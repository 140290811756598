import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Image from '../../components/image'
import {Link} from 'gatsby'

const ItemWarp = styled.ul`
  margin: 0px;
  padding-left: 0;
`
const Item = styled.li`
  padding: 10px;
  overflow: auto;
  border-bottom: #175D95 solid 3px;
  :hover{
    background:#efefef;
  }
`
const ListIcon = styled.div`
  float: left;
  margin: 0 15px 0 0;
  height: 48px;
  width: 48px;
`
// const ListBody = styled.div`
//   height:48px;
//   display:inline-block;
// `

const ListTitle = styled.h2`
 font: bold 20px/1.5 Helvetica, Verdana, sans-serif;
`
const ListFooter = styled.div`

`
const Calendar = styled.span`
  margin:8px;
  font-size:16px;
`
const UpdateCalendar = styled.span`
  margin:8px;
  font-size:16px;
  color:#EF767A;
`
const MiniIcon = styled.div`
  height: 16px;
  width: 16px;
  display: inline-block;
`
const Update = styled.div`
  display: inline-block;
`
const ArrowIcon = styled.div`
  height: 24px;
  width: 24px;
  display: inline-block;
  float:right;
`
const StyledLink = styled(Link)`
  color: #074684;
  text-decoration: none;
`
const CategoryPosts = (props) => (
  <StaticQuery
    query={graphql`
    query{
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }){
          edges{
            node{
              frontmatter{
                type
                title
                icon
                update(formatString: "MM DD, YYYY")
                categorySlug
                date(formatString: "MM DD, YYYY")
              }
              fields{
                slug
              }
              excerpt
            }
          }
        }
      }
    `}

    render={(data) => {
      const postlists = data.allMarkdownRemark.edges
      const postlist = postlists.filter((category)=>{
          return (category.node.frontmatter.categorySlug === props.category)
      })
      const posts = postlist.filter((category)=>{
        return (category.node.frontmatter.type === "post")
      })
      return (
      <div>
      <ItemWarp>
      {posts.map(({ node }) => {
          return (
            <Item key={node.frontmatter.title}>
            <StyledLink to={node.fields.slug}>
            <ListIcon>
            <Image filename={node.frontmatter.icon}></Image>
            </ListIcon>
            <ListTitle>{node.frontmatter.title}</ListTitle>
            <ListFooter>
            <MiniIcon><Image filename="calendar.png"></Image></MiniIcon><Calendar>{node.frontmatter.date}</Calendar>
            { node.frontmatter.update &&
            <Update>
            <MiniIcon><Image filename="update.png"></Image></MiniIcon><UpdateCalendar>{node.frontmatter.update}</UpdateCalendar>
            </Update>
            }
            <ArrowIcon><Image filename="arrow.png"></Image></ArrowIcon>
            </ListFooter>
            </StyledLink>
            </Item>
          )
        })}
        </ItemWarp>
      </div>
      );
    }}
  />
)
export default CategoryPosts
