import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

import rehypeReact from "rehype-react"
import Sample from "../components/Sample"
import CategoryPosts from "../components/CategoryPosts"
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "sample": Sample }
}).Compiler

class CategoryPageTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.categoryName} />
        <h1>{post.frontmatter.categoryName}</h1>
        <div>{renderAst(post.htmlAst)}</div>
        <hr/>
        <CategoryPosts category={post.frontmatter.categorySlug} />
      </Layout>
    )
  }
}

export default CategoryPageTemplate

export const pageQuery = graphql`
  query CategoryPageBySlug ($slug: String!){
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter:{type:{eq:"category"},categorySlug:{eq:$slug}})    {
        htmlAst
        frontmatter {
        categoryName
        categorySlug
      }
    }
  }
`
