import React from 'react'

const Sample = () => (

<div>
 <p>これはコンポーネントです。</p>
</div>

)
export default Sample
